<template>
  <v-dialog v-model="viewFilter" scrollable max-width="600px">
    <v-flex xs12>
      <v-card>
        <v-toolbar card prominent top offet-y>
          <v-toolbar-title class="headline grey-text">Filter</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.native="setfilteredData()">
            <v-icon color="green">fas fa-check</v-icon>
          </v-btn>
          <v-btn icon @click.native="viewFilter = false">
            <v-icon color="red">fas fa-times</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text>
          <v-flex xs12>
            <v-select :items="['None','Income', 'Expense', 'Transfer']"
                      label="Transaction Type"
                      chips
                      v-model="filterBy.transactionType"></v-select>
          </v-flex>
          <v-flex xs12>
            <v-autocomplete label="Budget"
                            :items="budget"
                            chips
                            v-model="filterBy.budget"></v-autocomplete>
          </v-flex>
          <v-flex xs12>
            <v-autocomplete :items="category"
                            v-model="filterBy.category"
                            chips
                            label="Category"></v-autocomplete>
          </v-flex>
          <v-flex xs12>
            <v-select :items="currency"
                      label="Currency"
                      chips
                      v-model="filterBy.currency"></v-select>
          </v-flex>
          <v-flex xs12>
            <v-select :items="paymentType"
                      v-model="filterBy.paymentType"
                      chips
                      label="Payment Type"></v-select>
          </v-flex>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-dialog>
</template>

<script>

  export default {
    props: ['filterBy'],
    data() {
      return {
        viewFilter: true,
        currency: ["None","JMD", "USD"],
        category: ["None","cat1", "cat2", "cat3", "cat4"],
        budget: ["None"],
        paymentType: ["None","Cash", "Credit Card", "Debit Card", "Bank Transfer", "Voucher"],
      }
    },
    methods: {
      setfilteredData: function () {
        this.$emit('filterData', this.filterBy)
      }
    }
  }
</script>
